// Breakpoint variables
$bp-tablet-sm-min: 650px;
$bp-tablet-lg-min: 900px;
$bp-desktop-min:   1280px;
$bp-wide-min:      1800px;

$bp-phone-max:     ($bp-tablet-sm-min - 1);
$bp-tablet-sm-max: ($bp-tablet-lg-min - 1);
$bp-tablet-lg-max: ($bp-desktop-min   - 1);
$bp-dekstop-max:   ($bp-wide-min      - 1);

$margin-zero-center:  0 auto;
$mobile-padding: 20px;
$med-width: 800px;

// Colors
$black: #000000;
$white: #ffffff;
$darkbrown: #2b2527;
$darkgreen: #335241;
$green: #45a363;

// Fonts
$mainFontFamily: 'Yanone Kaffeesatz', Tahoma, Verdana, sans-serif;