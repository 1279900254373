// 0 < w < 650
@mixin for-phone {
  @media (max-width: $bp-phone-max) {
    @content;
  }
}

// 650 <= w
@mixin for-phone-up {
  @media (min-width: $bp-tablet-sm-min) {
    @content;
  }
}

// 0 < w < 900
@mixin for-device-sm { // for: phone, tablet-sm
  @media (max-width: $bp-tablet-sm-max) {
    @content;
  }
}

// 600 <= w < 900
@mixin for-tablet-sm {
  @media (min-width: $bp-tablet-sm-min) and (max-width: $bp-tablet-sm-max) {
    @content;
  }
}

// 0 <= w < 1280
@mixin for-tablet-lg {
  @media (max-width: $bp-tablet-lg-max) {
    @content;
  }
}

// 1260 <= w < 1800
@mixin for-desktop {
  @media (min-width: $bp-desktop-min) and (max-width: $bp-dekstop-max) {
    @content;
  }
}

// 1800 <= w
@mixin for-widescreen {
  @media (min-width: $bp-wide-min) {
    @content;
  }
}