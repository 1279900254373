@import 
  '/src/components/abstracts/mixins',
  '/src/components/abstracts/variables';

html {
  scroll-behavior: smooth;
}

.hidden {
  display: none;
}

#skip-link-container {
  height: 0;

  .visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;
    background-color: transparent;

    &.focusable {
      &:focus {
        background-color: $white;
        color: $black;
        display: inline-block;
        margin: 10px;
        position: fixed !important;
        z-index: 600;
        overflow: visible;
        clip: auto;
        width: auto;
        height: auto;
      }
    }
  }
}

.App {
  text-align: left;
  font-family: Geneva, Verdana, sans-serif;

  img {
    width: 100%;
  }

  section {
    padding-top: 40px !important;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  header {
    background: $darkbrown;
    width: 100%;
    position: sticky;
    top: 0;

    a {
      color: $white;

      &.logo-name {
        display: flex;
        text-decoration: none;
        padding-top: 10px;
        padding-bottom: 10px;

        @include for-phone {
          align-items: center;
          justify-content: center;
        }

        .logo {
          margin-right: 10px;

          img {
            width: 65px;
          }
        }
    
        .name {
          span {
            font-family: $mainFontFamily;
            &.firstname {
              font-size: 40px;
              line-height: 40px;
            }
            &.occupation {
              font-size: 24px;
              line-height: 24px;
            }
          }
        }
      }
    }

    .container-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: $bp-desktop-min;
      margin: $margin-zero-center;
      padding: 0 20px;

      button {
        &.open-mobile-menu {
          display: none;

          @include for-phone {
            display: block;
            background: transparent;
            border: 0;
          }

          div {
            &.hamburger {
              background: url('../public/images/hamburger-menu.svg') no-repeat;
              background-size: 40px 40px;
              height: 40px;
              width: 40px;
            }
            &.close {
              background: url('../public/images/close-menu.svg') no-repeat;
              background-size: 40px 40px;
              height: 40px;
              width: 40px;
            }
          }
        }
      }

      nav {
        &.desktop {
          display: block;

          @include for-phone {
            display: none;
          }

          ul {
            list-style: none;
    
            li {
              display: inline-block;
              margin-left: 25px;
    
              &:first-child {
                margin-right: 0;
              }
    
              a {
                text-decoration: underline solid transparent;
                transition: text-decoration 0.5s ease;
    
                &:hover {
                  text-decoration: underline solid Currentcolor;
                }
              }
            }
          }
        }
      }
    }

    .mobile-menu {
      @include for-phone-up {
        display: none;
      }
      nav {
        &.mobile {
          display: none;
          background-color: $darkbrown;
  
          &.show {
            display: block;
          }
  
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: center;
    
            li {
              display: block;
              padding: 30px 0;
    
              a {
                text-decoration: underline solid Currentcolor;
                transition: text-decoration 0.5s ease;
    
                &:hover {
                  text-decoration: underline solid transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  main {
    max-width: $bp-desktop-min;
    margin: $margin-zero-center;

    @include for-tablet-lg {
      padding-left: $mobile-padding;
      padding-right: $mobile-padding
    }

    h1 { 
      font-size: 60px;
      line-height: 1.5;
      font-family: $mainFontFamily;
    }

    h2 {
      font-size: 50px;
      line-height: 1.5;
      text-align: center;
      font-family: $mainFontFamily;
    }

    h3 {
      font-size: 40px;
      line-height: 1.5;
      margin: 0;
      font-family: $mainFontFamily;
    }

    h4 {
      font-size: 28px;
      line-height: 1.5;
      margin: 0;
      font-family: $mainFontFamily;
      font-weight: 500;
    }

    p, ul, a, input, textarea, form {
      font-size: 22px;
      line-height: 1.5;
      color: $black;
    }

    p.required {
      font-size: 16px;
      line-height: 1.5;
    }

    span.red {
      color: #a30202;
    }

    #about {
      max-width: $med-width;
      width: 100%;
      margin: $margin-zero-center;

      .profile-pic {
        margin: 40px 0;
        text-align: center;

        img {
          max-width: 300px;
          width: 100%;
        }
      }
    }

    #projects {
      max-width: $bp-desktop-min;
      width: 100%;
      margin: $margin-zero-center;

      .projects-container {
        padding-left: 0;
        padding-right: 0;

        a {
          color: $darkgreen;
          text-decoration: underline solid Currentcolor;
          font-weight: bold;

          &:hover {
            text-decoration: underline solid transparent;
            transition: text-decoration 0.5s ease;
          }
        }

        .project + .project {
          margin-top: 100px;
        }

        .project {
          display: flex;
          justify-content: space-between;
          align-items: start;

          @include for-device-sm {
            display: block;
          }

          .project-img {
            
            @include for-device-sm {
              // max-width: 100%;
              text-align: center;
            }

            img {
              max-width: $med-width;
              width: 100%;
            }
          }
          .project-desc {
            width: 50%;
            margin-left: 40px;

            @include for-device-sm {
              width: 100%;
              margin-left: 0;
            }

            svg {
              height: 0.8em;
            }
          }
          .devtools {
            font-size: 20px;
            list-style-type: disc;
            margin-bottom: 30px;
          }
        }
        .see-more {
          text-align: center;
        }
      }
    }

    #skills {
      max-width: $med-width;
      width: 100%;
      margin: $margin-zero-center;

      .skills-container {
        .skills-list {
          -moz-column-count: 2;
          -moz-column-gap: 20px;
          -webkit-column-count: 2;
          -webkit-column-gap: 20px;
          column-count: 2;
          column-gap: 20px;
          padding: 0;

          @include for-phone {
            -moz-column-count: 1;
            -moz-column-gap: 20px;
            -webkit-column-count: 1;
            -webkit-column-gap: 20px;
            column-count: 1;
            column-gap: 20px;
            padding: 0;
          }

          .skills-list-item {
            // background-color: #fcefeb;
            border: 1px solid #cccccc;
            padding: 20px;
            margin-bottom: 10px;
            list-style-type: none;
            text-transform: uppercase;

            @include for-phone {
              font-size: 16px;
            }

            svg {
              margin-right: 20px;
            }
          }
        }
      }
    }

    #contact {
      max-width: $med-width;
      width: 100%;
      margin: $margin-zero-center;

      img {
        max-width: 250px;
        width: 100%;
        height: auto;
        text-align: center;
      }

      .success-message {
        margin-top: 50px;
        margin-bottom: 50px;
        font-weight: 500;
      }

      form {
        display: grid;
        margin-top: 40px;

        input {
          margin-top: 5px;
          margin-bottom: 20px;
          border: 1px solid $darkbrown;
          height: 40px;
        }
        textarea {
          margin-top: 5px;
          margin-bottom: 20px;
          border: 1px solid $darkbrown;
          height: 200px;
        }
        button {
          font-size: 22px;
          cursor: pointer;
          padding: 20px;
          width: 250px;
          background-color: $green;
          color: $black;
          border: none;
          margin-top: 20px;

          &:hover {
            background-color: #5dc77f;
          }
        }
      }
    }
  }

  #error-page {
    .profile-pic {
      text-align: center;
      margin-top: 100px;
      img {
        max-width: 250px;
        width: 100%;
        height: auto;
      }
    }
  }

  footer {
    margin-top: 100px;
    text-align: center;
    background-color: $darkbrown;
    color: $white;
    padding: 40px 20px;
    font-size: 14px;

    ul {
      list-style: none;
      padding: 0;

      li {
        display: inline-block;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        svg {
          stroke: $white;
          fill: $white;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
